import axios from "axios";

class API {
  service: any;
  
  constructor() {
    this.service = axios.create();

    this.service.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: any) => {
        return Promise.reject(console.error(error));
      }
    );
  }

  get(path:string, params?: any) {
    return this.service.get(path, params)
  }

  post(path:any, data:any, params?: any) {
    return this.service.post(path, data, params);
  }

  setHeaders(token:any) {
    this.service.interceptors.request.use((config:any) => {
      config.headers =  {
        "Content-type": "text/plain",
        "Access-Control-Allow-Origin" : "*",
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': "append,delete,entries,foreach,get,has,keys,set,values,Authorization",
        'Accept': '*/*',
        "Authorization": `Bearer ${token}`
      }

      return config;
    })
  }
}

export default new API();