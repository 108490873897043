import { IRule, IRulesList } from "../../interface/rule"
import { IField }  from "../../interface/field"

export default class Validator {
  field:IField

  constructor(field:IField) {
    this.field = field
  }

  check(value: string | null = null, value2: string | null = null) {
    const name = this.field.name as keyof IRulesList
    const rule = ruleFactory.createRule(name)
    const result = new Result

    return result.getResult(rule.test(value, value2), rule.message)
  }
}

class RuleFactory {
  constructor() {}

  createRule(name:keyof IRulesList) {
    return new Rule(rules[name]!)
  }
}

class Rule {
  condition:(val:string | null, val2?: string | null) => boolean
  message:string | null

  constructor(rule:IRule) {
    this.condition = rule.test
    this.message = rule.message
  }

  test(val:string | null, val2?: string | null) {
    return this.condition(val, val2)
  }
}

class Result {
  constructor() {}

  getResult(valid:boolean, message:string | null) {
    return {
      valid: valid,
      message: (!valid) ? message : null 
    }
  }
}

const ruleFactory = new RuleFactory

// TODO: вынести правила отдельно
const rules:IRulesList =  {
  name: {
    test: (a:string | null) => {
      return a !== null && a.length !== 0
    },
    message: 'Поле должно быть заполнено',
  },
  email: {
    test: (val:string | null) => {
      const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
      return val !== null && re.test(val)
    },
    message: 'Введите корректный адрес электронной почты',
  },
  phone: {
    test: (val:string | null) => {
      const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
      return (val !== null)
        ? re.test(val)
        : false
    },
    message: 'Введите корректный номер телефона',
  },
  password: {
    test: (val:string | null) => {
      return val !== null && val.length !== 0
    },
    message: 'Поле пароль должно быть заполнено',
  },
  confirm_password: {
    test: (a:string | null, b: string | null | undefined) => {
      if(a === null || b === null || b === undefined) return false

      return a.length === b.length && a === b
    },
    message: 'Пароли должны совпадать',
  },
  captcha_word: {
    test: (a:string | null) => {
      return a !== null && a.length !== 0
    },
    message: 'Введите текст с картинки',
  }
}