const constants = {
  FORM_FIELDS: [
    {
      name: 'email',
      label: 'Адрес емайл',
      type: 'email',
    },
    {
      name: 'name',
      label: 'Имя',
      type: 'text',
    },
    {
      name: 'phone',
      label: 'Телефон',
      type: 'phone',
    },
    {
      name: 'password',
      label: 'Пароль',
      type: 'password',
    },
    {
      name: 'confirm_password',
      label: 'Подтвердите пароль',
      type: 'password',
    },
    {
      name: 'captcha_word',
      label: 'Введите текст с картинки',
      type: 'text',
    }
  ],
  MASK: {
    phone: '+7 (###) ###-##-##',
  },
  API: {
    USER: {
      check: '/api/user/check',
      add: '/api/user/add/',
      getCaptcha: '/api/user/getCaptcha'
    },
  },
  APP_STATUS: {
    loading: 'loading',
    ready: 'ready',
  }
}

export default constants