<script setup lang="ts">
import { nextTick, reactive, ref, watch } from 'vue'
import { vMaska, MaskaDetail } from 'maska'
import constants from '../constants'
import { IField, IFieldProps } from '../interface/field'
import Validator from '../services/validate/index'

const inputValue = reactive<MaskaDetail>({
  masked: '',
  unmasked: '',
  completed: false,
})

const isActive = ref(false)
const isValid = ref()
const message = ref<string | null>(null)
const fieldInput = ref<HTMLInputElement | null>(null)

const props = withDefaults(defineProps<IFieldProps>(), {
  field: () => {
    return {
      name: 'tex-field',
      type: 'text',
    }
  },
  disabled: false,
  current: false,
  validationPayload: null,
})

const validator = new Validator(props.field)

const emit = defineEmits<{
  focusLeave: [ field:IField ],
  focusEnter: [ field:IField ]
}>()

const handleBlur = () => {
  const result = validator.check(inputValue.unmasked, props.validationPayload)
  
  isActive.value = false
  message.value = result.message
  
  if(!result.valid) {
    isValid.value = false
    return
  }
  isValid.value = true

  emit('focusLeave', Object.assign(props.field, {
    value: inputValue.masked,
  }))
}

const handleFocus = () => {
  isActive.value = true
  emit('focusEnter', props.field)
}

watch(() => props.current, () => {
  if(!props.current) return

  nextTick(() => {
    isActive.value = props.current

    if(isActive.value && fieldInput.value) fieldInput.value.focus()
  })
})

const handleTab = () => {
  if(fieldInput.value) fieldInput.value.blur()
}

const mask = () => {
  return (props.field.name === 'phone') ? `${constants.MASK.phone}` : null
}
</script>

<template>
  <div class="tx-field"
    :class="{ 'tx-field-valid': isValid && !isActive, 'tx-field-active': isActive }">
    <label for="">{{ field.label }}</label>
    <slot name="re-captcha"></slot>

    <input v-maska="inputValue" ref="fieldInput"
      @blur="handleBlur"
      @focus="handleFocus"
      :type="field.type"
      :disabled="!props.field.touched && !props.current"
      @keyup.tab.prevent="handleTab"
      @keyup.enter.prevent="handleTab"
      @keydown.tab.prevent
      @keydown.enter.prevent
      tabindex="1"
      :data-maska="mask()"
    />
    <div class="tx-field-message" v-if="!!message">
      <p>{{ message }}</p>
    </div>
  </div>
</template>

<style lang="scss">
.tx-field {
  position: relative;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  label {
    flex-basis: 100%;
    display: block;
    margin-bottom: .25rem;
  }

  input {
    flex-basis: min-content;
    flex-grow: 1;
    height: 40px;
    padding: 0 .5rem;
    outline: 0;
    border: 1px solid #847b7b;
    border-radius: 4px;
  }

  label {
    display: block;
  }
}

.tx-field-valid {
  input {
    opacity: .5;
  }
}
.tx-field-active {
  input {
    border-color: #cbbebe;
    box-shadow: inset 3px 3px 6px -1px rgba(0, 0, 0, 0.5);
  }
}

.tx-field-message {
  position: absolute;
  top: 100%; left: 0; 

  p {
    font-size: .75rem;
    margin: 0;
    color: rgb(160, 10, 10);
  }
}
</style>