<script setup lang="ts">
import { ref, onMounted, reactive, computed } from 'vue';

import { ModalsContainer } from 'vue-final-modal'

import constants from './constants'
import { IUser } from './interface/user'
import api from './services/api'

import Form from './components/Form.vue'
import Modal from './components/ModalBase.vue'
import Preloader from './components/PreloaderBase.vue';
import PWAPrompt from "./components/PWAPrompt.vue";

const appStatus = ref(constants.APP_STATUS.loading)
const user: IUser = reactive({ auth: false })
const showRegistrationForm = ref(false)

const success = function(data:IUser) {
  updateUser(data)
}

onMounted(async () => {
  appStatus.value = constants.APP_STATUS.ready
  const response:any = await loadLoginInfo();
  appStatus.value = constants.APP_STATUS.ready

  updateUser(response.data)
})

const loadLoginInfo = async(): Promise<Record<string, number | string>> => {
  const response:any = await api.get(constants.API.USER.check);

  return response.data;
}

const updateUser = (data: IUser) => {
  for(let [key, value] of Object.entries(data)) {
    user[key] = value
  }
}

const showPanel = computed(() => appStatus.value === constants.APP_STATUS.ready)
</script>

<template>
  <div class="user-pane">
    <PWAPrompt />
    <div v-if="!user.auth && showPanel" class="user-pane__variants">
      <a id="btnHeaderAuth" class="login" data-fancybox="" data-src="#login" href="javascript:;"><i class="fal fa-user-unlock"></i>&nbsp;&nbsp;Вход</a>
      <span class="registration" @click="showRegistrationForm = !showRegistrationForm">
        <i class="fal fa-sign-in-alt"></i>&nbsp;&nbsp;Регистрация
      </span>
    </div>
    <div v-if="user.auth && showPanel" class="user-pane__user">
      <a :href="`${user.link}`">{{ user.name }}</a>
    </div>
    <Preloader :status="appStatus" />

    <Modal
      v-model="showRegistrationForm"
      title="Регистрация"
      @close-modal="showRegistrationForm = false"
    >
      <Form v-if="!user.auth" @success-submit="success" />

      <div v-else class="tx-form-success">
        <h4>Вы успешно зарегистрировались!</h4>
        <p>Мы очень рады знакомству с Вами! Предлагаем ознакомиться с <a href="/action/">подборкой Акций</a>, в ней Вы найдете что-то для себя и близких с привлекательной скидкой!
          Или посетите наш <a href="/catalog/">Каталог товаров</a>
        </p>
      </div>
    </Modal>
  </div>
  <ModalsContainer />
</template>

<style lang="scss">
.user-pane {
  position: relative;
  height: 44px;
  padding: 0 1rem;
}
.user-pane__form {
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;
  z-index: 1111;
}
.user-pane__variants {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  a, span {
    font-size: 16px;
    color: #966d62;
    cursor: pointer;
    text-decoration: none;
  }
}

.user-pane__user {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  a {
    &:hover {
      color: var(--tx-colors-brown);
    }
  }
}

.tx-form-success {
  width: max-content;
  max-width: 100%;
}
</style>
