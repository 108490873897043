import { IField } from "../interface/field";

class FormNavigator {
  fields:IField[]
  index:number = 0
  validatedCounter:number = 0
  currentField:IField

  constructor(fields:IField[]) {
    this.fields = fields.map((field) => {
      return Object.assign(field, { touched: false })
    })

    this.currentField = fields[0]
    this.touch(this.currentField)
  }

  first() {
    this.reset()

    return this.fields[0]
  }

  next(isValid: boolean = true) {
    if(this.index >= this.fields.length) return
    
    this.currentField.valid = isValid

    if(this.currentField.valid) this.touch(this.currentField)
    this.setCurrent()

    return this.currentField
  }

  hasNext() {
    const result = this.fields.find((field:IField) => !field.touched)
    return result !== undefined
  }

  reset() {
    this.index = 0
    this.currentField = this.fields[0]
  }

  setCurrent(field:IField | null = null) {
    if(!this.hasNext()) return 

    this.currentField = field !== null 
      ? field
      : this.fields.find((field:IField) => !field.touched)!
  }

  touch(field:IField) {
    return field.touched = true
  }
}

export default FormNavigator