<script setup lang="ts">
const props = defineProps<{
  url: string
}>()
</script>

<template>
  <div class="tx-recaptcha">
    <img :src="props.url" alt="Введите текст с картинки">
  </div>
</template>

<style lang="scss">
.tx-recaptcha {
  height: 40px;
  img {
    height: 100%;
  }
}
</style>