<script setup lang="ts">
import { ref, reactive, onMounted, computed } from 'vue'

import constants from '../constants'
import api from '../services/api'
import FormNavigator from '../services/formNavigator'

import { IField, IFieldsList } from '../interface/field'
import { IRequestAdd, IResponse } from '../interface/request'
import { ICaptcha } from '../interface/captcha'

import Field from './Field.vue'
import Captcha from './ReCaptcha.vue'


const fields: IField[] = reactive(constants.FORM_FIELDS)
const errors: string[] = reactive([])

const requestFields:IFieldsList = reactive({
  name: '',
  email: '',
  phone: '',
  password: '',
  confirm_password: '',
  captcha_word: '',
})

const captcha:ICaptcha = reactive({
  captcha_path: '',
  captcha_sid: '',
});

const subscribe = ref(true)

const formNavigator = new FormNavigator(fields)
const currentField = ref<IField>(formNavigator.first())

const step = () => {
  currentField.value = formNavigator.next()!
}

const setCurrent = (field:IField) => {
  formNavigator.setCurrent(field)
}

const emit = defineEmits<{
  successSubmit: [ result: any ]
}>()

const handleDataChange = (field:IField) => {
  step()

  saveValue(field.value!, field.name as keyof IFieldsList)
}

const saveValue = function(value: string, fieldName: keyof IFieldsList) {
  requestFields[fieldName] = value 
}

const loadCaptcha = async(): Promise<Record<string, number | string>> => {
  const response:any = await api.get(constants.API.USER.getCaptcha);

  return response.data;
}

const canSubmit = computed(() => {
  return Object.values(requestFields).filter((field:string) => {
    return field.length === 0
  }).length === 0
})

const submitForm = async function() {
  console.log(canSubmit.value)
  if(!canSubmit.value) return
  
  const addUserRequestData:IRequestAdd = {
    EMAIL: requestFields.email,
	  NAME: requestFields.name,
    PERSONAL_PHONE: requestFields.phone,
    PASSWORD: requestFields.password,
    captcha_sid: captcha.captcha_sid,
    captcha_word: requestFields.captcha_word,
    recaptcha_response_callback: '',
    subscribe: subscribe.value,
  }

  const response = await api.post(constants.API.USER.add, addUserRequestData)

  const result:IResponse = response.data

  if(result.errors.length) {
    result.errors.forEach((error: any) => {
      errors.push(error.message)
    })

    const response: any = await loadCaptcha();
    updateCaptcha(response.data)

    return
  }

  emit('successSubmit', result.data)
}

function clearErrors() {
  errors.length = 0;
}

const updateCaptcha = (data:any) => {
  captcha.captcha_path = data.captcha_path
  captcha.captcha_sid = data.captcha_sid
}

onMounted(async () => {
  const response: any = await loadCaptcha();
  updateCaptcha(response.data)
})
</script>

<template>
  <div class="tx-form">
    <div class="tx-form-row"
       v-for="(field, index) in fields"
       :key="index"
      >
      <Field 
        :class="{ 'tx-captcha-field': field.name === 'captcha_word'}"
        :field="field"
        :current="field.name === currentField.name"
        :validationPayload="requestFields.password"
        @focus-leave="handleDataChange"
        @focus-enter="setCurrent">
        <template v-if="field.name === 'captcha_word'" #re-captcha>
          <Captcha :url="captcha.captcha_path" />
        </template>
      </Field>
    </div>

    <div class="tx-form-row tx-form-agreement">
        <div class="tx-form-checkbox">
          <input type="checkbox" name="pdn_checkbox" id="pdn_checkbox" required="true" checked="true" disabled="true" />
          <label class="form-check-label" for="pdn_checkbox">
            Даю согласие <a href="/pdn_full/" target="_blank">на обработку персональных данных</a>
          </label>
        </div>
        <div class="tx-form-checkbox">
          <input type="checkbox" name="subscribe_checkbox" checked id="subscribe_checkbox"
            v-model="subscribe"
          />
          <label class="form-check-label" for="subscribe_checkbox">
            Подписаться на рассылку
          </label>
        </div>
    </div>

    <div class="super-important-field">
      <input name="sc_phone" value="+7(900) 555-55-56" type="text">
    </div>

    <div v-if="errors.length" class="tx-form-errors">
      <div class="tx-form-errors-list">
        <h4>Пожалуйста, исправьте следующие ошибки:</h4>
        <ol>
          <li v-for="error in errors">{{ error }}</li>
        </ol>
        <span @click="clearErrors">Всё понятно...</span>
      </div>
    </div>
    
    <button @click.prevent="submitForm()">Отправить</button>
  </div>
</template>

<style lang="scss">
.tx-form {
  width: 340px;

  button {
    width: 100%;
  }

  h3 {
    margin-top: 0;
  }
}

.tx-form-errors {
  position: relative;
}
.tx-form-errors-list {
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 97%;
  padding: 1.5rem;
  border: 1px var(--tx-colors-brown) solid;
  border-radius: 25px;
  background-color: var(--tx-form-bg-color);
  transform: translateX(-50%);

  &:after {
    position: absolute;
    bottom: -6px;
    left: 50%;
    display: block;
    width: 10px;
    height: 10px;
    content: "";
    border: var(--tx-colors-brown) solid;
    border-width: 0 0 1px 1px;
    background-color: var(--tx-form-bg-color);
    transform: translateX(-50%) rotate(-45deg);
    z-index: 1;
  }

  ol {
    padding-left: 1rem;
    list-style: decimal;
  }

  li {
    margin-bottom: .25rem;
    line-height: 1.25;

    &:last-child {
      margin-bottom: 0;
    }
  }

  span {
    display: flex;
    text-align: center;
    align-items: center;
    width: min-content;
    height: 40px;
    font-size: 18px;
    white-space: nowrap;
    margin: 1.5rem auto 0;
    color: var(--tx-colors-brown);
    padding: .25rem .5rem;
    border: 1px solid var(--tx-colors-brown);
    background-color: var(--tx-menu-bg-color);
    border-radius: 4px;
    cursor: pointer;
  }

  h4 {
    font-weight: 400;
    line-height: 1.15;
    margin-top: 0;
    color: var(--tx-colors-brown);
  }
}

.tx-form-agreement {
  margin: 2rem 0 1rem;
}

.tx-form-captcha_word {
  position: relative;
  padding-top: .75rem;
  display: flex;
  align-items: stretch;

  label {
    position: absolute;
    left: 0; top: 0;
  }

  .tx-input {
    input { border-radius: 0 .25rem .25rem 0; }
  }

  img {
    border-radius: .25rem 0 0 .25rem;
    border:  solid var(--tx-colors-brown);
    border-width: 1px 0 1px 1px;
  }
}

.super-important-field {
  display: none;
  visibility: hidden;
}
</style>
