<template>
  <div class="pwa-install" v-if="shown">
    <div class="row">
      <div class="col-12">
        <p>Предлагаем установить приложение Интернет-магазин Текс-Дизайн на ваше устройство</p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button @click="installPWA">
          Установить
        </button>
      </div>
      <div class="col">
        <button @click="dismissPrompt">
          Продолжить в браузере
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    shown: false,
  }),

  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.shown = true
    })
  },

  methods: {
    dismissPrompt() {
      this.shown = false
    },

    installPWA() {
      this.installEvent.prompt()
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt() // Hide the prompt once the user's clicked
        if (choice.outcome === 'accepted') {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      })
    },
  }
}
</script>

<style>
.pwa-install {
  display: block;
  width: 400px;
  background-color: #fff;
  border: 1px gray solid;
  padding: 1rem;
  animation: myAnim 2s ease 0s 1 normal forwards;
}

@keyframes myAnim {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.row {
  display: flex;
}

.col {
  flex-basis: 50%;
  flex-shrink: 0;
}

button {
  display: flex;
  height: 44px;
  color: #EDE2DA;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #926D5A;
  border: 0;
  outline: 0;
  text-transform: uppercase;
  letter-spacing: .5;
  cursor: pointer;
}

</style>