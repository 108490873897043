<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'

defineProps<{
  title?: string
}>()

const emit = defineEmits<{
  (e: 'closeModal'): void
}>()
</script>

<template>
  <VueFinalModal>
    <div class="tx-modal">
      <span class="tx-modal-close" @click="emit('closeModal')"></span>
      <div class="tx-modal-title">
        <h3>{{ title }}</h3>
      </div>
      <div class="tx-modal-content">
        <slot />
      </div>
    </div>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
.tx-modal {
  position: relative;
  margin: 0 auto;
  width: min-content;
  max-height: 100vh;
  max-width: 420px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
  background-color: var(--tx-colors-beige);

  h3 {
    margin: 0;
  }
}

.tx-modal-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  width: 20px;
  height: 20px;

  &:before,
  &:after {
    position: absolute;
    left: 50%; top: 50%;
    content: "";
    width: 20px;
    height: 0;
    border-bottom: 2px solid var(--tx-colors-brown);
    transition: transform .5s ease-in-out;
  }

  &:before {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }

  &:after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }

  &:hover {
    &:before {
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }

    &:after {
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
  }
}
</style>