<script setup lang="ts">
import constants from '../constants'
import { computed } from 'vue'

const props = defineProps<{status: string}>()

const show = computed(() => {
  console.log('status', props.status, props.status !== constants.APP_STATUS.ready ? true : false)
  return props.status !== constants.APP_STATUS.ready ? true : false
})
</script>

<template>
  <div class="preloader" v-if="show">
    <div class="preloader-loading"></div>
  </div>
</template>

<style lang="scss" scoped>
.preloader {
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.preloader-loading {
  flex-grow: 1;
  background-image: url('../assets/img/db-ring.gif');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: auto 100%;
}
</style>